<template>
  <div>
    <b-card title="Ekle">
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="formData.title"
          placeholder="Başlık"
          class="mb-2"
        />
      </b-form-group>
      <b-form-group
        label="Durum"
        label-for="status"
      >
        <v-select
          id="status"
          v-model="formData.status"
          label="title"
          :options="option"
          :reduce="option => option.id"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BFormInput, BCard, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Edit',
  components: {
    BFormInput,
    BCard,
    BFormGroup,
    BButton,
    vSelect,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        title: null,
        status: '1',
      },
      option: [{ id: '1', title: 'Aktif' }, { id: '0', title: 'Pasif' }],
    }
  },
  computed: {
    marketingFocusData() {
      return this.$store.getters['marketingFocus/getMarketingFocus']
    },
    saveStatus() {
      return this.$store.getters['marketingFocus/getMarketingFocusSaveStatus']
    },
  },
  watch: {
    marketingFocusData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('marketingFocus/marketingFocusView', this.$route.params.id)
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('marketingFocus/marketingFocusSave', this.formData)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
